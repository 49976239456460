<template>
  <div class="policy">
    <div class="container">
      <h1 class="page-top">
        <div class="title">약관 및 정책</div>
      </h1>
      <div class="row">
        <aside class="col-12 col-lg-3">
          <Lnb/>
        </aside>
        <article class="col-12 col-lg-9">
          <div class="inner">
            <h2>후원형 이용약관</h2>
            <div class="section">
              <h3>제 1 조 목적</h3>
              <p>
                이 약관은 오마이컴퍼니(이하"회사"라고 합니다)의
                인터넷사이트(http://www.ohmycompany.com)를 통하여 제공하는
                크라우드펀딩 서비스 및 기타 서비스(이하 "서비스"라고 합니다)와
                관련하여 회사와 회원 간의 권리와 의무, 책임사항 및 회원의 서비스
                이용절차에 관한 사항을 규정함을 목적으로 합니다.
              </p>
            </div>
            <div class="section">
              <h3>제 2 조 용어의 정의</h3>
              <p>
                이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
                본 약관에 명시되지 않은 용어는 관련 법령이 정하는 바에 따르며,
                그 외에는 일반적인 상거래관행에 의합니다.
              </p>
              <ul class="tight">
                <li>
                  ① 서비스 : 회원이 전자적 시스템에 접속하여 홈페이지에서
                  온라인상의 거래 등을 할 수 있게 본 약관에 따라 회사가 제공하는
                  서비스를 말합니다.
                </li>
                <li>
                  ② 홈페이지 : 본 약관에 따라 회사가 제공하는 서비스가 구현되는
                  온라인상의 웹페이지(https://www.ohmycompany.com)를 말합니다.
                </li>
                <li>
                  ③ 프로젝트 : 프로젝트 진행자가 펀딩을 위해 홈페이지 페이지에
                  개설하여 게재한 후 회사의 승인을 받아 홈페이지에 게시된
                  사항으로서 펀딩의 대상을 말합니다.
                </li>
                <li>
                  ④ 프로젝트 진행자(이하 "진행자"라 합니다.) : 회사의 회원가입
                  기본약관에 따른 회원의 자격을 부여받은 자 중에서 회사와 별도의
                  약정을 체결하여 홈페이지에서 본 약관에 따른 프로젝트를
                  개설하여 프로젝트 후원자들로부터 펀딩을 받으려는 자를
                  말합니다.
                </li>
                <li>
                  ⑤ 프로젝트 후원자(이하 "후원자"라 합니다.) : 회사의 회원가입
                  기본약관에 따른 회원의 자격을 부여받은 자 중에서 진행자가
                  개설한 프로젝트에 펀딩을 하려는 자를 말합니다.
                </li>
                <li>
                  ⑥ 펀딩의 신청 : 후원자가 프로젝트의 내용에 따라 펀딩을 하기
                  위하여 본 약관에 따른 방법으로 펀딩의 의사표시를 하는 것을
                  말합니다.
                </li>
                <li>
                  ⑦ 펀딩의 취소 : 후원자가 프로젝트의 펀딩 기간 내에 펀딩의
                  의사표시를 철회하는 것을 말합니다.
                </li>
                <li>
                  ⑧ 펀딩 달성률 : 진행자가 목표한 모집예정금액을 기준으로 펀딩의
                  신청 접수가 완료된 모집금액이 차지하는 비율을 말합니다.
                </li>
                <li>
                  ⑨ 리워드 : 진행자가 펀딩의 의사표시를 한 후원자에게 보상품의
                  형태로 제공하는 유무형의 제품, 서비스를 말합니다.
                </li>
                <li>
                  ⑩ ID : 회원의 식별과 서비스 이용을 위하여 회원이 선정하고
                  회사가 승인한 이메일을 말합니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 3 조 서비스의 제공</h3>
              <ul class="tight">
                <li>
                  <p>
                    ① 회사가 본 약관에 따라 제공하는 서비스의 종류는 다음 각
                    호와 같습니다.
                  </p>
                  <ul class="list_depth1">
                    <li>
                      1. 프로젝트 개설 서비스 : 프로젝트 개설, 프로젝트 게시
                    </li>
                    <li>
                      2. 펀딩 서비스 : 펀딩의 참여 접수, 펀딩달성률 게시, 펀딩
                      가능 여부 통지, 펀딩결과 통보 등
                    </li>
                    <li>
                      3. 위 각 호의 서비스 제공을 위해 필요하거나 이에 부수하는
                      서비스
                    </li>
                  </ul>
                </li>
                <li>
                  ② 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
                  다만, 회사는 서비스를 일정범위로 분할하여 각 범위 별로
                  이용가능시간을 별도로 지정할 수 있으며 이러한 경우에는 그
                  내용을 사전에 공지합니다.
                </li>
                <li>
                  ③ 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장,
                  통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을
                  일시적으로 중단할 수 있으며, 이 경우 회사는 회원에게
                  통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가
                  있는 경우 사후에 통지할 수 있습니다.
                </li>
                <li>
                  ④ 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수
                  있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 4 조 서비스의 신청</h3>
              <ul class="tight">
                <li>
                  ① 본 약관 제 3 조의 서비스를 이용하려는 자는 회원가입
                  기본약관에 따라 회원의 자격을 취득하여 로그인한 후 서비스
                  신청을 하여야 합니다.
                </li>
                <li>
                  ② 회사는 회원이 입력한 ID, 비밀번호가 회사에 등록된 것과
                  일치할 경우 본 약관에 따른 서비스 신청을 접수합니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 5 조 서비스의 이용료 등</h3>
              <ul class="tight">
                <li>
                  ① 회사는 본 약관에 따라 펀딩을 하는 회원(후원자)에게 별도의
                  수수료를 징수하지 않습니다.
                </li>
                <li>
                  ② 진행자가 홈페이지에 프로젝트를 개설하여 펀딩을 받으려는 경우
                  회사와의 별도의 약정에 따라 수수료를 회사에 지급하여야 합니다.
                </li>
                <li>
                  ③ 기타 서비스에 있어서 회사가 서비스 이용에 따른 수수료를
                  부과하고자 할 경우에는 회사는 부과기준 및 기타 상세한 내용을
                  시행 7일 전에 홈페이지에 공지합니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 6 조 프로젝트의 개설</h3>
              <ul class="tight">
                <li>
                  ① 진행자는 프로젝트 페이지를 생성하여 프로젝트 개설을 회사에
                  신청한 후 회사가 프로젝트를 심사하여 승인한 경우에 한하여 해당
                  프로젝트는 홈페이지에 게시 또는 등록(이하 "게시")됩니다.
                </li>
                <li>
                  <p>
                    ② 제1항에 따라 프로젝트가 홈페이지에 게시되기 위하여는 다음
                    각 호의 기준에 따라 회사의 심사를 거쳐 승인되어야 합니다.
                  </p>
                  <ul class="list_depth1">
                    <li>
                      1. 회사의 프로젝트 개설에 따른 규정과 절차를 준수하였는가
                    </li>
                    <li>
                      2. 회사가 제공한 프로젝트 개설 양식의 항목을 빠짐없이
                      기재하였는가
                    </li>
                    <li>
                      3. 회사가 요구한 프로젝트 관련 파일 (이미지, 동영상, 음성
                      파일 등)을 규격에 맞게 제공하였는가
                    </li>
                    <li>
                      4. 관계법령에 위배되거나 사회의 안녕질서 혹은 미풍양속을
                      저해하려는 목적이 있지는 않는가
                    </li>
                    <li>
                      5. 범죄적 행위, 국익 또는 사회적 공익을 저해하려는 목적이
                      있지는 않는가
                    </li>
                    <li>
                      6. 타인의 명예를 손상시키거나 불이익을 주려는 목적이
                      있지는 않는가
                    </li>
                    <li>7. 기타 회사가 정한 프로젝트 심사 조건에 부합하는가</li>
                  </ul>
                </li>
                <li>
                  ③ 심사를 신청한 프로젝트의 내용에 허위, 기재누락, 오기가
                  있어서는 안 되며, 이를 준수하지 않아 발생한 불이익에 대해서는
                  진행자가 모든 책임을 부담하여야 합니다. 진행자가 작성 및
                  제공한 프로젝트 내용 및 서류에 대한 모든 책임은 해당
                  진행자에게 있습니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 7 조 프로젝트 정보의 게재</h3>
              <ul class="tight">
                <li>
                  ① 제 6 조에 따라 회사의 승인을 받은 프로젝트의 경우 진행자는
                  후원자들의 펀딩 판단에 도움이 될 수 있는 프로젝트에 대한
                  정보를 홈페이지에 게재할 수 있습니다.
                </li>
                <li>
                  <p>
                    ② 제1항에도 불구하고 회사는 진행자가 게재한 프로젝트의 내용,
                    정보 등이 다음 각 호에 해당한다고 판단하는 경우 사전통지
                    없이 삭제할 수 있습니다. 다만, 시정이 가능하다고 판단되는
                    경우에는 진행자에게 소명 또는 시정을 요구할 수 있습니다.
                  </p>
                  <ul class="list_depth1">
                    <li>
                      1. 진행자가 제공한 회원정보 또는 프로젝트의 정보, 개설을
                      위해 제출한 서류가 조작되었거나 허위임이 판명된 경우
                    </li>
                    <li>
                      2. 제3자의 지적 재산권 및 기타 권리를 침해하는 내용인 경우
                    </li>
                    <li>
                      3. 회사로부터 사전승인 받지 아니한 내용을 게시한 경우
                    </li>
                    <li>
                      4. 진행자가 회사 사이트와 게시판에 음란, 비방, 욕설,
                      명예훼손, 개인정보 무단공개 등 이에 준하는 게시물을
                      게재하거나 관련 사이트를 링크하는 경우
                    </li>
                    <li>
                      5. 진행자가 프로젝트 정보를 불법적으로 변경 또는 조작한
                      경우
                    </li>
                    <li>6. 기타 관계법령에 위반된다고 판단되는 경우</li>
                  </ul>
                </li>
                <li>
                  ③ 승인되어 게시된 프로젝트는 프로젝트 기간이 종료한 후에도
                  홈페이지에 게시될 수 있으며, 삭제가 불가능합니다.
                </li>
                <li>
                  ④ 제6항에 따른 회사의 승인에도 불구하고 게시된 프로젝트의
                  내용에 허위, 기재누락, 오기가 있음에 따라 발생한 분쟁, 법적
                  책임, 불이익에 대해서는 진행자가 모든 책임을 단독으로
                  부담하여야 합니다. 진행자가 작성 및 제공한 프로젝트 내용 및
                  서류에 대한 모든 책임은 해당 진행자에게 있습니다.
                </li>
                <li>
                  ⑤ 후원자가 홈페이지에 게재된 내용에 의존하여 펀딩 판단을 한
                  경우 이로부터 발생하는 결과에 대하여 회사는 회사의 고의 또는
                  과실이 없는 이상 책임을 지지 않습니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 8 조 프로젝트의 내용변경 및 공지</h3>
              <ul class="tight">
                <li>
                  ① 회사가 프로젝트를 승인하기 이전에 진행자는 마이페이지의
                  개설프로젝트에서 프로젝트의 내용을 수정할 수 있습니다.
                </li>
                <li>
                  ② 회사가 프로젝트를 승인한 이후에는 진행자는 원칙적으로
                  서비스약정 또는 회사의 규정에 따라 변경이 금지된 것으로 정해진
                  사항(이하 "변경금지사항")에 대하여는 정정 또는 삭제(이하
                  "변경")를 할 수 없습니다. 다만, 예외적으로 변경금지사항을
                  변경하기 위해서 별도의 변경신청(전화, 이메일)을 하여 회사가
                  해당 변경신청에 대하여 승인을 한 경우에 한하여 변경하여 게시할
                  수 있습니다.<br/>
                  (*변경금지사항: 펀딩 방식, 펀딩 목표 금액, 리워드 금액)
                </li>
                <li>
                  ③ 진행자는 후원자가 언제든지 확인을 할 수 있도록 변경되거나
                  추가된 프로젝트의 내용을 '최근소식' 게시판에 업데이트 하는
                  방법으로 공지하여야 합니다.
                </li>
                <li>
                  ④ 제2항, 제3항에도 불구하고 진행자가 변경금지사항을 임의로
                  변경하거나 변경한 내용을 공지하지 아니한 경우에는 해당
                  프로젝트의 후원자와의 관계에서 발생할 수 있는 법적 분쟁과
                  책임, 불이익에 대하여 회사는 책임을 지지 않습니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 9 조 프로젝트의 취소</h3>
              <ul class="tight">
                <li>
                  ① 진행자는 프로젝트가 승인되기 이전에는 회사에 요청하여 회사가
                  정한 방법에 따라 프로젝트를 취소할 수 있습니다.
                </li>
                <li>
                  ② 진행자는 프로젝트가 승인된 이후 프로젝트 기간이 종료되기
                  전까지는 회사에 프로젝트의 취소를 요청하여 서비스약정 및
                  회사의 안내에 따라 프로젝트의 취소 절차를 밟아야 합니다.
                </li>
                <li>
                  ③ 프로젝트가 성공한 경우에 진행자는 정당한 사유가 없는 한
                  프로젝트를 취소할 수 없습니다. 다만, 천재지변, 국가비상사태 등
                  불가항력적 사유로 인하여 프로젝트를 취소하게 된 경우는 예외로
                  합니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 10 조 펀딩의 신청 전 유의사항</h3>
              <ul class="tight">
                <li>
                  ① 후원자는 본 약관에 따른 펀딩을 하기 전에 홈페이지를 통해
                  제공되는 정보뿐만 아니라 프로젝트 기간, 리워드 제공의 조건 등
                  프로젝트의 내용을 충분히 숙지한 후 펀딩의 신청을 하여야
                  합니다.
                </li>
                <li>
                  ② 진행자가 프로젝트 게시 전 펀딩의 시기 등 유형별로 리워드
                  제공의 조건과 기준, 방법 등을 다르게 설정하기를 회사에
                  요청하는 경우, 회사는 그 조건과 기준이 합리적이고 명확한
                  기준에 의한 것인 경우 그 내용을 홈페이지에 게시하며, 이 경우
                  후원자가 위 내용에 따라 리워드를 제공받는데 제한이 있을 수
                  있습니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 11 조 후원자들의 의견교환</h3>
              <ul class="tight">
                <li>
                  ① 후원자는 홈페이지에 개설된 게시판을 통해 프로젝트에 대한
                  질의 또는 답변을 게시하는 방법으로 진행자와 의견을 교환할 수
                  있습니다.
                </li>
                <li>
                  ② 회사는 홈페이지에 게시된 후원자들의 의견을 임의로 삭제하거나
                  수정하지 않습니다. 다만, 다른 법률에 근거가 있거나 게시된 글이
                  회원가입 기본약관에 따라 삭제될 수 있는 경우에는 그러하지
                  아니합니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 12 조 펀딩의 참여 방법</h3>
              <ul class="tight">
                <li>
                  ① 후원자는 펀딩하려는 프로젝트의 페이지에서 "후원하기"를
                  의미하는 배너를 클릭하여 회사가 제공하는 양식에 따라
                  펀딩내용(모집금, 리워드의 내용, 수량, 추가 후원금액 등)과
                  결제방법을 입력하고 회사가 제공하는 모집금의 결제절차(결제예약
                  포함)를 마치면 회사에 대한 펀딩의 참여가 이루어지며, 이에
                  대하여 회사가 접수를 하면 펀딩의 신청 접수가 완료됩니다.
                </li>
                <li>
                  ② 회사는 진행자가 합리적이고 명확한 기준에 따라 후원자의 자격
                  또는 모집금에 제한을 두기를 요청한 경우 그에 따라 펀딩의
                  참여를 제한할 수 있습니다.
                </li>
                <li>
                  ③ 후원자는 각 프로젝트별로 회사가 제공하는
                  결제수단(신용/체크카드예약결제, 신용/체크카드, 실시간계좌이체,
                  가상계좌)을 통하여 홈페이지 내에서 모집금에 대한 결제를 할 수
                  있습니다.
                </li>
                <li>
                  <p>
                    ④ 회사는 정확하고 안전한 결제 처리를 위해 관계법령에 따라
                    아래와 같은 후원자의 정보를 후원자의 동의를 받아 결제
                    대행사에 제공하게 됩니다.
                  </p>
                  <table class="omc-table">
                    <colgroup>
                      <col style="width: 45%"/>
                      <col style="width: 55%"/>
                    </colgroup>
                    <thead>
                    <tr>
                      <th>결제와 관련한 정보를 제공 받는 자</th>
                      <th>제공되는 정보</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        나이스정보통신(주)<br/>
                        네이버파이낸셜(주)<br/>
                        아임포트
                      </td>
                      <td>
                        고객명/휴대폰번호/생년월일/신용(체크)
                        카드번호/유효기간/비밀번호(앞 2자리) 등 결제정보
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  ⑤ 후원자는 1개의 프로젝트에 중복하여 펀딩할 수 있습니다.
                </li>
                <li>
                  ⑥ 후원자의 펀딩 참여 결과는 이메일 또는 문자메시지(알림톡)를
                  통해 통보 받게 됩니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 13 조 펀딩의 취소</h3>
              <ul class="tight">
                <li>
                  ① 펀딩의 취소는 본 약관 및 홈페이지의 이용안내에 규정된 사항에
                  따릅니다.
                </li>
                <li>
                  ② 후원자는 프로젝트 기간의 종료 전(본 약관에서 정한 사유로
                  프로젝트 기간이 연장된 경우에는 연장된 프로젝트 기간의 종료
                  전)까지만 펀딩을 취소할 수 있습니다.
                </li>
                <li>
                  ③ 이미 펀딩을 한 후원자가 모집금을 수정하고 싶은 경우에는
                  추가로 펀딩을 하거나 또는 이전 결제를 취소한 후 재결제를
                  하여야 합니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 14 조 펀딩달성률의 게시</h3>
              <ul class="tight">
                <li>
                  ① 펀딩의 참여가 완료되면 홈페이지에 펀딩달성률이 게시됩니다.
                  다만, 본 약관에 따라 펀딩의 취소가 있거나 펀딩의 참여 효력이
                  상실되는 경우에는 펀딩달성률은 변동될 수 있습니다.
                </li>
                <li>
                  ② 제1항에도 불구하고 본 조에 따라 전달되는 모집금에는 프로젝트
                  기간의 종료 시까지 이루어진 취소 및 변경 신청이 추가로 반영될
                  수 있어 펀딩달성률과 차이가 있을 수 있습니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 15 조 펀딩결과의 게시 및 통보</h3>
              <ul class="tight">
                <li>
                  <p>
                    ① ‘성공해야 리워드’유형 또는 ‘무조건 리워드’유형의 개설한
                    프로젝트의 경우 펀딩기간이 종료되면 회사는 펀딩결과를 아래와
                    같은 기준에 따라 "성공" 또는 "마감(실패)"로 구분하여
                    홈페이지에 게시됩니다.
                  </p>
                  <ul class="list_depth1">
                    <li>
                      1. "성공" : 후원자의 펀딩에 따라 결제된 모집금이 진행자가
                      목표한 금액 이상인 경우
                    </li>
                    <li>
                      2. "마감(실패)" : 후원자의 후원에 따라 결제된 모집금이
                      진행자가 목표한 금액 미만인 경우
                    </li>
                  </ul>
                </li>
                <li>
                  ② 본 조에 따라 펀딩결과가 "실패"한 경우에도 해당 프로젝트에
                  대하여 프로젝트의 재개설이 가능합니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 16 조 펀딩 금액의 전달</h3>
              <ul class="tight">
                <li>
                  ① ‘성공해야 리워드’유형의 프로젝트 펀딩결과가 "성공"인 경우
                  후원자가 결제한 모집금에서 서비스 수수료, 결제 수수료를 제한
                  후 서비스약정에서 정한 바에 따라 진행자에게 전달됩니다. 다만,
                  진행자와 오마이컴퍼니가 따로 협의한 경우에는 협의한 내용에
                  따라 조건부 지급될 수 있습니다.
                </li>
                <li>
                  ② 회사는 특별한 사정이 없는 한 서비스약정에서 정한 바에 따라
                  진행자에 대한 확인절차가 완료된 경우에 한하여 위 완료된
                  날로부터 영업일 이내에 진행자에게 모집금 전액을 전달합니다.
                </li>
                <li>
                  ③ 제1항, 제2항에도 불구하고 진행자가 후원자들에게 펀딩을 받은
                  프로젝트를 이행할 수 없거나 리워드를 제공할 수 없음이 분명한
                  경우 또는 서비스약정상 모집금을 전달할 수 없다고 규정된
                  경우에는 회사는 모집금의 지급을 유보하거나 전달하지 아니할 수
                  있습니다.
                </li>
                <li>
                  ④ 모집금 지급 도중 제3항과 같은 사유가 발생한 경우에는 회사는
                  지급하지 않은 잔여 모집금의 지급을 유보할 수 있고, 필요한 경우
                  이미 지급받은 모집금에 대하여도 후원자들에게 환불하도록 요청할
                  수 있습니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 17 조 모집금의 반환 및 환불</h3>
              <ul class="tight">
                <li>
                  <p>
                    ① 회사는 다음 각 호의 사유가 발생한 경우 해당 사유가 발생한
                    날로부터 지체없이 모집된 모집금의 결제를 일괄적으로
                    취소합니다. 다만, 실제로 결제의 취소에 따른 환불 처리가
                    완료되는 기간은 카드사 등 금융기관에 따라 달라질 수
                    있습니다.
                  </p>
                  <ul class="list_depth1">
                    <li>
                      1. 프로젝트가 프로젝트 기간 중에 중단 또는 취소된 경우
                    </li>
                    <li>2. 제 13 조에 따라 펀딩의 취소가 있는 경우</li>
                    <li>
                      3. 제 15 조 제1항 제2호에 따라 펀딩결과가 "실패"인 경우
                    </li>
                  </ul>
                </li>
                <li>
                  ② 제1항의 경우 진행자는 프로젝트 기간 동안 펀딩된 모집금에
                  대하여 어떠한 권리도 주장할 수 없습니다.
                </li>
                <li>
                  ③ 제 16 조 제4항에 따른 회사의 환불요청이 있거나
                  서비스약정에서 정한 환불사유가 발생한 경우 진행자는 지체없이
                  이미 수령한 후원금을 후원자들에게 반환해야 합니다.
                </li>
                <li>
                  ④ 회사는 개별 서비스의 특성에 따라 환불 규정을 달리 정하여
                  공지할 수 있습니다. 이 경우 위와 같이 정한 환불규정이 본
                  약관에 우선하여 적용됩니다.
                </li>
                <li>
                  ⑤ 프로젝트 기간이 종료된 이후 오마이컴퍼니를 통한 환불은
                  불가능하며, 참여한 프로젝트의 진행자에게 환불을 요청해야
                  합니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 18 조 모집금의 사용</h3>
              <ul class="tight">
                <li>
                  ① 진행자는 모집금이 프로젝트의 목적에 맞게 사용될 수 있도록
                  모든 노력을 기울여야 합니다.
                </li>
                <li>
                  ② 진행자는 본 약관에 따라 전달받은 모집금을 진행자가
                  프로젝트에서 약속한 프로젝트의 수행 및 완료, 약속한 리워드의
                  제공 및 이를 위한 발송을 위한 경비 등으로만 사용하여야 하며,
                  이를 이행하지 않음에 따라 발생하는 불이익 및 법적 책임은
                  진행자가 모두 부담하여야 합니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 19 조 리워드의 제공</h3>
              <ul class="tight">
                <li>
                  ① 진행자는 프로젝트 및 후원자들에게 제공할 리워드와 관련하여
                  후원자들이 문의하는 사항들에 대하여 성실하게 답변 및 응대할
                  의무가 있습니다.
                </li>
                <li>
                  ② 제15조 제1항 제1호, 제15조 제1항에 따라 펀딩결과가 "성공"인
                  경우 진행자는 프로젝트 페이지에 게재하여 약속한
                  내용(프로젝트의 수행, 리워드의 내용, 수량, 제공 일정 등)을
                  성실하게 이행하여야 합니다. 만약 진행자가 고의 또는 과실로
                  이를 이행하지 않거나 게재한 내용과 다르게 혹은 일부만 이행하는
                  경우에 발생할 수 있는 모든 책임(후원자에 의한 청구 등)은
                  진행자에게 있습니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 20 조 개인정보의 제공</h3>
              <ul class="tight">
                <li>
                  ① 회사는 리워드 제공 및 리워드 제공을 위해 필요한 행위를 하는
                  데에 있어 필요한 개인 정보를 회사가 제공하는 ‘개인정보 수집 및
                  이용 동의서’에 대한 확인 및 동의를 후원자로부터 받아
                  진행자에게 제공합니다.
                </li>
                <li>
                  ② 진행자는 정보수집의 다른 목적을 밝히고 동의를 받지 않는 이상
                  제공받은 후원자의 정보를 제1항의 동의를 받지 아니한 목적으로
                  수집 이용하여서는 안되며, 이를 위반하여 발생한 개인정보 노출
                  사고 등에 대한 모든 책임은 회사의 고의 또는 과실이 없는 이상
                  진행자가 단독으로 부담하여야 합니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 21 조 면책</h3>
              <ul class="tight">
                <li>
                  ① 회사는 오마이컴퍼니 플랫폼을 제공하여 온라인상의 거래를
                  중개하는 중개자이며 크라우드펀딩계약(리워드 제공 및 펀딩 등
                  프로젝트에 관한 계약 등 포함)의 당사자가 아닙니다. 따라서
                  후원자와의 위 크라우드펀딩계약에 관하여 발생하는 법적 분쟁에
                  대한 책임은 진행자가 단독으로 부담합니다.
                </li>
                <li>
                  ② 회사가 본 약관에 따라 정한 사유로 인하여 서비스 제공을 제한
                  또는 중지시킨 경우 회사는 이로 인해 발생한 회원의 손해에
                  대하여 배상할 책임을 지지 않습니다.
                </li>
                <li>
                  ③ 회사는 회원이 전자우편 등 개인정보를 부실하게 기재하거나
                  또는 본 약관에 따라 입력하여야 하는 정보의 변경의무를 다하지
                  아니하여 발생한 손해에 대해 배상할 책임이 없습니다.
                </li>
                <li>
                  ④ 홈페이지에 게재된 정보, 자료, 사실 등의 진실성, 정확성,
                  신뢰성은 회사가 보장하지 않습니다. 게시한 자료의 활용 여부는
                  후원자와 진행자 각자의 책임과 판단에 맡겨져 있으며, 그로 인해
                  야기되는 결과에 대해 회사는 일체의 책임을 지지 않습니다.
                </li>
                <li>
                  ⑤ 회사는 회원 간 및 회원과 제3자간에 서비스를 매개로 발생한
                  분쟁에 대해 개입할 의무가 없습니다. 회사는 서비스 이용과
                  관련하여 회원의 고의 또는 과실로 인하여 회원 또는 제3자에게
                  발생한 손해에 대하여 책임을 부담하지 않습니다. 다만,
                  관련법령에서 달리 규정하고 있는 사항이 있으면 그에 따릅니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 22 조 권리의 귀속</h3>
              <p>
                회사가 제공하는 서비스(데이터, 소프트웨어, 게시물의 저작권 등)에
                관한 권리는 회사에게 귀속됩니다.
              </p>
            </div>
            <div class="section">
              <h3>제 23 조 불가항력에 따른 면책</h3>
              <p>
                회사는 천재지변, 전시, 사변 또는 이에 준하는 불가항력이라고
                인정되는 사유로 인하여 회원에게 발생하는 손해에 대하여 책임을
                지지 아니합니다.
              </p>
            </div>
            <div class="section">
              <h3>제 24 조 약관의 적용</h3>
              <ul class="tight">
                <li>
                  ① 본 약관에서 정하지 아니한 사항에 대해서는 회사의 회원가입
                  기본약관 및 전자상거래 등에서의 소비자 보호에 관한 법률,
                  소비자피해보상규정 등 관계법규를 따릅니다. 다만, 관계법규 및
                  관련약관에 정하는 바가 없으면 일반적인 상관례를 따릅니다.
                </li>
                <li>
                  ② 회사와 회원 간에 개별적으로 합의한 사항이 본 약관에서 정한
                  사항과 다를 때에는 그 합의사항을 본 약관에 우선하여
                  적용됩니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 25 조 약관의 게시 및 변경</h3>
              <ul class="tight">
                <li>
                  ① 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 초기
                  화면에 게시합니다.
                </li>
                <li>
                  ② 회사는 약관의 규제에 관한 법률, 정보통신망법 등 관계법령을
                  위반하지 않는 범위에서 본 약관을 개정할 수 있습니다.
                </li>
                <li>
                  ③ 회사가 약관을 개정할 경우에는 적용일자를 명시하여 제1항의
                  방법에 따라 그 개정약관을 [7]일 이상 공지합니다. 다만,
                  회원에게 불리한 내용으로 약관의 개정이 있는 경우에는 공지 외에
                  일정기간 서비스 내 전자우편, 전자쪽지, 로그인시 동의창 등의
                  전자적 수단을 통해 따로 명확히 통지하도록 합니다.
                </li>
                <li>
                  ④ 회사가 제3항에 따라 개정약관을 공지 또는 통지하면서 회원에게
                  [5]일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된
                  것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이
                  명시적으로 거부의 의사표시를 하지 아니한 경우 회원이
                  개정약관에 동의한 것으로 봅니다.
                </li>
                <li>
                  ⑤ 회원이 개정약관의 적용에 동의하지 않는 경우 회사는
                  개정약관의 내용을 적용하지 않으며, 이 경우 회원은 제7조에서
                  정한 바에 따라 이용계약을 해지할 수 있습니다. 다만, 기존
                  약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사가
                  동의하지 않은 회원과의 이용계약을 해지할 수 있습니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 26 조 준거법 및 재판관할</h3>
              <ul class="tight">
                <li>
                  ① 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.
                </li>
                <li>
                  ② 회사와 회원 간 발생한 분쟁에 관한 소송은 민사소송법에 따른
                  전속적 합의관할에 따라 대한민국 서울중앙지방법원을 전속적
                  관할법원으로 합니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <p>
                &lt;시행일&gt;<br/>
                본 약관은 2021년 8월 19일부터 적용됩니다.
              </p>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import Lnb from "./Lnb.vue";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pagePolicyRewardTerms";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {
    Lnb,
  },
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.policy";
</style>